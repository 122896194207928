"use client";

import { ReactNode, useContext } from "react";
import { ReportTemplateContext, ReportTemplateContextType } from "./ReportContext.tsx";

type ReportBodyProps = {
    className?: string;
    children: ReactNode;
};

const ReportBody = ({ className, children }: ReportBodyProps) => {
    const { reportTemplateContextState } = useContext<ReportTemplateContextType>(ReportTemplateContext);

    const style = className
        ? `text-[${reportTemplateContextState.fontMultiplier}em] ${className}`
        : `text-[${reportTemplateContextState.fontMultiplier}em]`;

    return <div className={style}>{children}</div>;
};

export { ReportBody };
export type { ReportBodyProps };
