import { Logger, pino } from "pino";
import { datadogLogs } from "@datadog/browser-logs";
import type { Log, LogContext, Tracer } from "@forge-ui-components/tracing";

let logger: Logger;

if (process.env.NEXT_PUBLIC_ENV !== "local") {
    datadogLogs.init({
        clientToken: "pub3d33da2e6c12be75d426f717b10537b8",
        site: "datadoghq.com",
        service: "forge-render",
        env: process.env.NEXT_PUBLIC_ENV,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ["log", "info", "error", "warn"],
        sessionSampleRate: 100,
    });
}

const getLogger = () => {
    if (!logger) {
        logger = pino();
    }
    return logger;
};

const buildLogWithTrace = (logObject: Record<string, any>) => {
    const time = new Date().toISOString();
    const record = { ...logObject, time };
    return record;
};

/**
 * Should be used for logging client-side across forge UI client components. All logs will be forwarded to datadog from the browser.
 */
export const log: Log = {
    debug: (message: string, logContext: LogContext) => {
        getLogger().info(
            buildLogWithTrace({
                ...logContext,
                message,
            })
        );
    },
    info: (message: string, logContext: LogContext) => {
        getLogger().info(
            buildLogWithTrace({
                ...logContext,
                message,
            })
        );
    },
    warn: (message: string, logContext: LogContext) => {
        getLogger().warn(
            buildLogWithTrace({
                ...logContext,
                message,
            })
        );
    },
    error: (message: string, logContext: LogContext, cause?: Error) => {
        getLogger().error(
            buildLogWithTrace({
                ...logContext,
                message,
                cause: cause?.stack,
            })
        );
    },
};

export const tracer: Tracer = {
    log,
};

export default tracer;
