"use client";

import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from "react";

type ContactProfileContextState = {
    entitlementRoles: string[];
};

type ContactProfileContextType = {
    contactProfileContextState: ContactProfileContextState;
    setContactProfileContextState: Dispatch<SetStateAction<ContactProfileContextState>>;
};

const ContactProfileContext = createContext({} as ContactProfileContextType);

type ContactProfileContextProviderProps = {
    children: ReactNode;
};

const CONTACT_PROFILE_STORAGE_REFRESH_INTERVAL = 300000; // 5 minutes in ms

const getJwt = async (): Promise<string | undefined> => (window as any).JwtService?.getJwt();

const ContactProfileContextProvider = ({ children }: ContactProfileContextProviderProps) => {
    const [contactProfileContextState, setContactProfileContextState] = useState({} as ContactProfileContextState);

    const contextData = useMemo(
        () => ({
            contactProfileContextState,
            setContactProfileContextState,
        }),
        [contactProfileContextState, setContactProfileContextState]
    );

    useEffect(() => {
        const getContactProfile = async () => {
            const jwt = await getJwt();
            const response = await fetch("/research/services/contactProfile", {
                credentials: "include",
                ...(jwt && {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }),
            });
            const profile = await response.json();

            localStorage.setItem("contact-profile", JSON.stringify(profile));
            localStorage.setItem("contact-profile-last-refreshed", new Date().getTime().toString());
            setContactProfileContextState(profile);
        };
        const contactProfileString = localStorage.getItem("contact-profile");
        const contactProfileLastRefreshedString = localStorage.getItem("contact-profile-last-refreshed");

        const contactProfileLastRefreshed = contactProfileLastRefreshedString
            ? JSON.parse(contactProfileLastRefreshedString)
            : undefined;
        const now = new Date().getTime();

        if (
            !contactProfileString ||
            !contactProfileLastRefreshed ||
            now > contactProfileLastRefreshed + CONTACT_PROFILE_STORAGE_REFRESH_INTERVAL
        ) {
            getContactProfile();
        } else {
            setContactProfileContextState(JSON.parse(contactProfileString));
        }
    }, []);

    return <ContactProfileContext.Provider value={contextData}>{children}</ContactProfileContext.Provider>;
};

export type { ContactProfileContextType };
export { ContactProfileContext, ContactProfileContextProvider };
