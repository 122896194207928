"use client";

import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from "react";
import { getCookie } from "cookies-next";

type FontMultiplier = 1 | 1.25 | 1.5;

type ReportTemplateContextState = {
    galleryOpen: boolean;
    currentExhibitId: string | undefined;
    galleryZoomLevel: number;
    fontMultiplier: FontMultiplier;
};

type ReportTemplateContextType = {
    reportTemplateContextState: ReportTemplateContextState;
    setReportTemplateContextState: Dispatch<SetStateAction<ReportTemplateContextState>>;
};

const ReportTemplateContext = createContext({} as ReportTemplateContextType);

type ReportTemplateContextProviderProps = {
    children: ReactNode;
};

const INITIAL_REPORT_CONTEXT_STATE: ReportTemplateContextState = {
    galleryOpen: false,
    currentExhibitId: undefined,
    galleryZoomLevel: 1,
    fontMultiplier: 1.25,
};

const ReportTemplateContextProvider = ({ children }: ReportTemplateContextProviderProps) => {
    const [reportTemplateContextState, setReportTemplateContextState] = useState(
        INITIAL_REPORT_CONTEXT_STATE as ReportTemplateContextState
    );

    const contextData = useMemo(
        () => ({
            reportTemplateContextState,
            setReportTemplateContextState,
        }),
        [reportTemplateContextState, setReportTemplateContextState]
    );

    const fontCookieValue = getCookie("gsPortalFontSize", {});

    // Wrap in useEffect to avoid infinite loop
    useEffect(() => {
        if (fontCookieValue) {
            setReportTemplateContextState((prevState) => ({
                ...prevState,
                fontMultiplier: parseFloat(fontCookieValue) as FontMultiplier,
            }));
        }
    }, [fontCookieValue]);

    return (
        <ReportTemplateContext.Provider value={contextData}>
            <div>{children}</div>
        </ReportTemplateContext.Provider>
    );
};

export type { ReportTemplateContextType, ReportTemplateContextProviderProps, FontMultiplier };
export { ReportTemplateContext, ReportTemplateContextProvider };
