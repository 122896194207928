"use client";

import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from "react";
import { getBookmarkGlossary } from "@forge-ui-components/funl-client-data-loader";
import { log } from "@forge-ui-components/tracing-client";

type BookmarkGlossaryElement = {
    bookmarkId: string;
    bookmarkPath: string;
    bookmarkType: string;
    createdDate: number;
    userGuid: string;
};

type BookmarkGlossaryContextState = Record<string, BookmarkGlossaryElement>;

type BookmarkGlossaryContextType = {
    bookmarkGlossaryContextState: BookmarkGlossaryContextState;
    setBookmarkGlossaryContextState: Dispatch<SetStateAction<BookmarkGlossaryContextState>>;
};

const BookmarkGlossaryContext = createContext({} as BookmarkGlossaryContextType);

type BookmarkGlossaryContextProviderProps = {
    children: ReactNode;
};

const LOG_CONTEXT = { moduleName: "BookmarkGlossaryContextProvider" };
const BOOKMARK_GLOSSARY_STORAGE_REFRESH_INTERVAL = 300000; // 5 minutes in ms

const BookmarkGlossaryContextProvider = ({ children }: BookmarkGlossaryContextProviderProps) => {
    const [bookmarkGlossaryContextState, setBookmarkGlossaryContextState] = useState(
        {} as BookmarkGlossaryContextState
    );

    const contextData = useMemo(
        () => ({
            bookmarkGlossaryContextState,
            setBookmarkGlossaryContextState,
        }),
        [bookmarkGlossaryContextState, setBookmarkGlossaryContextState]
    );

    useEffect(() => {
        const bookmarkGlossaryString = localStorage.getItem("bookmark-glossary");
        const bookmarkGlossaryLastRefreshedString = localStorage.getItem("bookmark-glossary-last-refreshed");

        const bookmarkGlossaryLastRefreshed = bookmarkGlossaryLastRefreshedString
            ? JSON.parse(bookmarkGlossaryLastRefreshedString)
            : undefined;
        const now = new Date().getTime();

        if (
            !bookmarkGlossaryString ||
            !bookmarkGlossaryLastRefreshed ||
            now > bookmarkGlossaryLastRefreshed + BOOKMARK_GLOSSARY_STORAGE_REFRESH_INTERVAL
        ) {
            try {
                getBookmarkGlossary().then((glossary) => {
                    localStorage.setItem("bookmark-glossary", JSON.stringify(glossary));
                    localStorage.setItem("bookmark-glossary-last-refreshed", now.toString());
                    setBookmarkGlossaryContextState(glossary);
                });
            } catch (exception) {
                log.error(`Failed to store bookmark glossary to context w/ exception: ${exception}`, LOG_CONTEXT);
            }
        } else {
            setBookmarkGlossaryContextState(JSON.parse(bookmarkGlossaryString));
        }
    }, []);

    return <BookmarkGlossaryContext.Provider value={contextData}>{children}</BookmarkGlossaryContext.Provider>;
};

export type { BookmarkGlossaryContextType };
export { BookmarkGlossaryContext, BookmarkGlossaryContextProvider };
