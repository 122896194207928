import { LogContext } from "@forge-ui-components/tracing";
import { tracer } from "@forge-ui-components/tracing-client";

const LOG_CONTEXT: LogContext = { moduleName: "FunlClientDataLoader" };
const FUNL_BASE = "/funl";

type Bookmark = {
    bookmarkId: string;
    bookmarkType: string;
    bookmarkResponseContext: any;
    createdDate: number;
    userGuid: string;
};

type SetItemBookmarkProps = {
    assetId: string;
    type: "report" | "media" | "pages";
};

type RemoveItemBookmarkProps = {
    bookmarkId: string;
};

export type { SetItemBookmarkProps, RemoveItemBookmarkProps };

const getJwt = async (): Promise<string | undefined> => await (window as any).JwtService?.getJwt();

export const getAllBookmarks = async () => {
    const jwt = await getJwt();
    const response = await fetch(`${FUNL_BASE}/bookmarks`, {
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(jwt && { Authorization: `Bearer ${jwt}` }),
        },
    });

    if (response.ok) {
        return response.json();
    }

    tracer.log.error(`Get Bookmarks request failed with status code: ${response.status}`, LOG_CONTEXT);
    throw new Error(`Failed to fetch bookmarks`);
};

export const setBookmark = async ({ assetId, type }: SetItemBookmarkProps): Promise<Bookmark> => {
    const jwt = await getJwt();

    const payload = {
        assetId,
        bookmarkType: type,
        createdDate: Math.round(Date.now() / 1000),
    };

    const response = await fetch(`${FUNL_BASE}/bookmarks`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(jwt && { Authorization: `Bearer ${jwt}` }),
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        tracer.log.error(
            `Failed to set bookmark for item: ${assetId}, request failed with status code: ${response.status}`,
            LOG_CONTEXT
        );
        throw new Error(`Failed with status code: ${response.status} to set bookmark for item: ${assetId}`);
    }

    return response.json();
};

export const removeBookmark = async ({ bookmarkId }: RemoveItemBookmarkProps): Promise<boolean> => {
    const jwt = await getJwt();

    const response = await fetch(`${FUNL_BASE}/bookmarks/${bookmarkId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(jwt && { Authorization: `Bearer ${jwt}` }),
        },
    });

    if (!response.ok) {
        tracer.log.error(
            `Failed to remove bookmark: ${bookmarkId}, request failed with status code: ${response.status}`,
            LOG_CONTEXT
        );
        return false;
    }
    return response.ok;
};

export const getBookmarkGlossary = async () => {
    const jwt = await getJwt();

    const response = await fetch(`${FUNL_BASE}/bookmarks/glossary`, {
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...(jwt && { Authorization: `Bearer ${jwt}` }),
        },
    });

    if (response.ok) {
        return response.json();
    }

    tracer.log.error(`Get Bookmark Glossary request failed with status code: ${response.status}`, LOG_CONTEXT);
    throw new Error(`Failed to fetch bookmark glossary`);
};
